import qs from 'qs';

export async function getData(endpoint, options = {}) {
    const {
        headers = {},
        queryParams = {},
        handleErrorLocally = false
    } = options;

    const queryString = qs.stringify(queryParams, { indices: false });

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/introweken/${endpoint}${queryString ? `?${queryString}` : ''}`;

    const res = await fetch(url, {
        headers: await prepareHeaders(headers),
    });

    return await handleResponse(res, handleErrorLocally)
}

export async function postData(endpoint, data, options = {}) {
    const {
        headers = {},
        queryParams = {},
        handleErrorLocally = false
    } = options;

    const queryString = qs.stringify(queryParams, { indices: false });

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/introweken/${endpoint}${queryString ? `?${queryString}` : ''}`;

    const res = await fetch(url, {
        method: 'POST',
        headers: await prepareHeaders(headers, (data instanceof FormData) ? null : 'application/json'),
        body: data instanceof FormData ? data : JSON.stringify(data),
    });

    return await handleResponse(res, handleErrorLocally)
}

const prepareHeaders = async (headers, contentType = 'application/json') => {
    return {
        ...headers,
        ...(contentType && {'Content-Type': contentType}),
        'Accept': 'application/json'
    };
}

const handleResponse = async (response, handleErrorLocally) => {
    if (!response.ok && !handleErrorLocally) {
        let responseMessage = await response.json()
        let errorMessage = responseMessage["error"]
        let errorLink = responseMessage["link"]
        console.log(response)
        switch (response.status) {
            case 400:
                throw Object.assign(new Error("Bad Request"), { status: 400, key: "BadRequest"});
            case 401:
                throw Object.assign(new Error("Unauthenticated"), { status: 401, key: "Unauthenticated"});
            case 402:
                throw Object.assign(new Error("Payment Required"), { status: 402, key: "PaymentRequired", link: errorLink});
            case 404:
                throw Object.assign(new Error("Not Found"), { status: 404, key: "NotFound"});
            case 409:
                throw Object.assign(new Error("Conflict error"), { status: 409, key: "AlreadyRegistered"});
            default:
                throw Object.assign(new Error("Something went wrong"), { status: 500, key: "WentWrong"});
        }
    }

    try {
        return await response.json();
    } catch {
        // Catch empty 200 response
        return "";
    }
}


