import styled from 'styled-components';
import {getData} from "../../api/fetch";

export const Container = styled.div`
    max-width: 872px; // 840 + (padding-left + padding-right)
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
`;

export const ConditionalWrap = ({condition, wrap, fallback, children}) => {
    return condition ? wrap(children) : fallback ? fallback(children) : children;
}

export async function getTranslation(key) {
    return await getData(`form/student/translate?key=${key}`, {handleErrorLocally: true})
}