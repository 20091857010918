import styled from 'styled-components';
import {Heading3} from '../Heading/Heading';
import {HTMLText} from '../Text/Text';

const StyledDescriptionBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 24px 20px;
    background-color: var(--color-input-description-box);
    border-radius: 8px;
`;

const Title = styled(Heading3).attrs({
    as: "p",
    $noMargin: true,
})`
    font-size: var(--fs-label);
    display: inline-block;
`;

export const DescriptionBox = ({label, description}) => {
    return (
        <StyledDescriptionBox>
            {label && <Title>{label}</Title>}
            {description && <HTMLText dangerouslySetInnerHTML={{__html: description}} />}
        </StyledDescriptionBox>
    );
}