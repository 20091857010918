import {Input} from '../InputBase/Input';
import styled from 'styled-components';
import {InputFieldTooltip} from '../Tooltip/InputTooltip';
import calendar from '../../assets/icons/calendar.svg';
import calendarDisabled from '../../assets/icons/calendar-disabled.svg';

const StyledDateInputField = styled.div`
    position: relative;
`;

const StyledInput = styled(Input)`
    &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator {
        opacity: 0;
    }

    &::-webkit-date-and-time-value {
        text-align: left;
    }

    &:after {
        pointer-events: none;
        content: "";
        position: absolute;
        top: 50%;
        right: ${({$hasTooltip}) => $hasTooltip ? "50px" : "13px"};
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-color: var(--color-secondary);
    }

    &:not(:read-only)::after {
        mask: url(${calendar}) no-repeat center;
    }

    &:read-only::after {
        mask: url(${calendarDisabled}) no-repeat center;
    }
`;

export const DateInputField = ({id, label, required, readOnly, tooltip, ...props}) => {
    return (
        <StyledDateInputField>
            <StyledInput
                id={id}
                type="date"
                placeholder={label}
                defaultValue={props?.defaultValue}
                {...(props.register && props.register(props.name, {
                    required,
                    onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() }
                }))}
                readOnly={readOnly}
                $hasTooltip={!!tooltip}
            />
            <InputFieldTooltip tooltip={tooltip} />
        </StyledDateInputField>
    );
}