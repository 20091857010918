import styled from 'styled-components';
import {useOutletContext} from "react-router-dom";
import {useFormContext} from "../../contexts/FormContext";
import {getFormattedPrice} from '../../utils/helpers';

const StyledOrderSummary = styled.div``;

const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
`;

const Name = styled.p`
    width: 50%;
    font-size: var(--fs-text);
`;

const Price = styled.p`
    display: flex;
    justify-content: center;
    font-size: var(--fs-label);
    font-weight: var(--fw-semi-bold);
`;

const StyledHr = styled.hr`
    height: 1px;
    background: var(--color-progress-step-divider);
    opacity: 0.4;
    border: none;
`;

const TotalAmount = styled.p`
    font-size: var(--fs-heading-2);
    font-weight: var(--fw-semi-bold);
`;

export let totalAmount = 0.0;

export const OrderSummary = () => {
    const {data} = useOutletContext()
    const {formValues} = useFormContext();
    const values  = formValues[Object.keys(formValues)[0]];
    totalAmount = 0.0;
    let items = [];
    let options = [];

    const addSummaryItem = (item) => {
        if (item.price > 0) {
            totalAmount += item.price
            items.push(
                <Item key={item.id}>
                    <Name>{item.title}</Name>
                    <Price>&euro; {getFormattedPrice(item.price)}</Price>
                </Item>
            );
        }
    }

    // Get potential values from questions or add them to a options array
    for (let step in data.steps) {
        for (let question in data.steps[step].questions) {
            let item = data.steps[step].questions[question]
            switch (item.type) {
                case "textField":
                    if (item.price > 0) {
                        addSummaryItem(item)
                    }
                    break;
                case "checkbox":
                case "radio":
                    for (let option in item.options) {
                        options.push(item.options[option])
                    }
                    break;
                default:
            }
        }
    }

    // Go through every question's answer
    for (let value in values) {
        if (Array.isArray(values[value])) {
            for (let item in values[value]) {
                if (values[value][item]) {
                    let data = values[value][item]
                    let checkBoxData = options.find(item => item.id === data)
                    if (checkBoxData) {
                        addSummaryItem(checkBoxData)
                    } else {
                        addSummaryItem(data)
                    }
                }
            }
        } else if (options.find(item => item.id === values[value])) {
            let radioData = options.find(item => item.id === values[value])
            addSummaryItem(radioData)
        } else if (values[value] && values[value].price) {
            addSummaryItem(values[value])
        }
    }


    return (
        <StyledOrderSummary>
            {items}
            <StyledHr />
            <Item>
                <TotalAmount>Total:</TotalAmount>
                <TotalAmount>&euro; {getFormattedPrice(totalAmount)}</TotalAmount>
            </Item>
        </StyledOrderSummary>
    );
}

export default OrderSummary;