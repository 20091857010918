import {useHasHover} from '../../hooks/useHasHover';
import {useState} from 'react';
import {TooltipArrow, TooltipBase, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger} from './TooltipBase';
import styled from 'styled-components';
import {ReactComponent as TooltipIcon} from '../../assets/icons/tooltip.svg';

const StyledTooltip = styled.div`
    display: inline-block;
`;

const StyledTooltipIcon = styled(TooltipIcon)`
    width: ${({$small}) => $small ? "20px" : "24px"};
    height: ${({$small}) => $small ? "20px" : "24px"};
`;

const StyledTooltipArrow = styled(TooltipArrow)`
    width: 16px;
    height: 8px;
    fill: var(--color-white);
`;

export const Tooltip = ({className, children, variant}) => {
    const hasHover = useHasHover();
    const [open, setOpen] = useState(false);

    return (
        <StyledTooltip className={className}>
            <TooltipProvider>
                <TooltipBase
                    delayDuration={0}
                    onOpenChange={(e) => {
                        setOpen(e)
                    }}
                    open={open}
                >
                    <TooltipTrigger asChild onClick={e => {
                        !hasHover && e.preventDefault();
                        setOpen(true);
                    }}>
                        <StyledTooltipIcon $small={variant === "small"} />
                    </TooltipTrigger>
                    <TooltipPortal>
                        <TooltipContent>
                            {children}
                            <StyledTooltipArrow />
                        </TooltipContent>
                    </TooltipPortal>
                </TooltipBase>
            </TooltipProvider>
        </StyledTooltip>
    )
}