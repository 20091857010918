import {Environments} from "../constants/enums";

// Get unique list of objects by key (source https://stackoverflow.com/a/56768137)
export function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

// Check if item in parameter is an object
export function isObject(item) {
    return typeof item === "object" && !Array.isArray(item) && item !== null;
}

// Remove empty values from object (source https://medium.com/@zaitsev1393/best-way-to-remove-empty-values-from-js-objects-ad691bd084b9)
export const removeEmptyValues = (object) => {
    return typeof (!!object && object === "object" && !Array.isArray(object))
        ? Object.fromEntries(Object.entries(object).filter(([_, value]) => value))
        : object;
}

// Only remove empty string values
export const removeEmptyStringValues = (object) => {
    Object.keys(object).forEach(key => {
        if (object[key] === "") {
            delete object[key];
        }
    });
    return object;
}

// Get current environment based on .env variable
export const getCurrentEnvironment = () => {
    const currentEnvironment = process.env.REACT_APP_ENVIRONMENT_TYPE;

    switch (currentEnvironment) {
        case Environments.DEV:
            return {type: Environments.DEV, showLabel: true, showBar: true, label: "DEV"};
        case Environments.TEST:
            return {type: Environments.TEST, showLabel: true, showBar: true, label: "TEST"};
        case Environments.ACCEPT:
            return {type: Environments.ACCEPT, showLabel: true, showBar: true, label: "ACCEPTANCE", shortLabel: "ACC"};
        case Environments.PRODUCTION:
            // Do not show the bar on production
            return {type: Environments.PRODUCTION, showLabel: false, showBar: false};
        default:
            return {type: Environments.DEFAULT, showLabel: false, showBar: true, label: "Let op: Er is geen REACT_APP_ENVIRONMENT_TYPE ingesteld."};
    }
}

// Turn price into other format
export const getFormattedPrice = (price) => {
    return new Intl.NumberFormat('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(price);
}