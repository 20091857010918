import styled, {css, keyframes} from "styled-components";
import {ReactComponent as SpinnerIcon} from '../../assets/icons/spinner.svg';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(360deg);
    }
`;

export const Spinner = styled(SpinnerIcon)`
    user-select: none;
    display: inline-block;
    width: 24px;
    height: 24px;
    animation: ${rotate} 1.5s ease infinite;
    
    ${({$white}) => $white && css`
        path {
            fill: var(--color-white);
        }
    `}
`;