import styled, {css} from 'styled-components';
import {truncateMixin} from '../Utils/Mixins';

export const textStyles = css`
    color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-text)'};
    font-size: var(--fs-text);
    line-height: 1.25;
    margin: ${({$noMargin}) => $noMargin ? '0' : '0 0 12px 0'};
    overflow-wrap: anywhere;
    text-align: ${({$align}) => $align === "center" ? "center" : "left"};
    ${({$italic}) => $italic && 'font-style: italic;'};
    ${({$weight}) => $weight && `font-weight: var(--fw-${$weight});`};
    ${({ $truncate }) => $truncate && truncateMixin};
    
    a {
        color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-link)'};
        text-decoration: underline;
        font-weight: var(--fw-bold);
        
        &:hover {
            text-decoration: none;
        }
    }
`;

export const Text = styled.p`
    ${textStyles};
`;

export const HTMLText = styled.div`
    p, ul, ol {
        ${textStyles};
    }
    
    strong { font-weight: var(--fw-bold); }
    em { font-style: italic; }
    u { text-decoration: underline }
    ul { padding-left: 20px; }
    ol { padding-left: 20px; }

    & > *:last-child {
        margin-bottom: 0;
    }
`;