import styled from 'styled-components';

export const Input = styled.input`
    width: 100%;
    min-height: 50px;
    padding: ${({$hasTooltip}) => $hasTooltip ? "13px 50px 13px 16px" : "13px 16px"};
    border-radius: 10px;
    color: var(--color-text);
    font-size: var(--fs-text);
    line-height: 1;
    background-color: var(--color-white);
    appearance: none;
    outline: none;
    border: 1px solid var(--color-input-stroke);
    
    &:disabled, &:read-only {
        color: var(--color-input-read-only-text);
        background-color: var(--color-input-read-only-background);
    }

    &::placeholder {
        color: var(--color-text);
        opacity: 0.3;
        font-style: italic;
    }
`;