import styled from 'styled-components';
import {Heading3} from '../Heading/Heading';
import {TextInputField} from './TextInputField';
import {TextAreaField} from './TextAreaField';
import {CheckboxesField} from './CheckboxesField';
import {HTMLText, Text} from '../Text/Text';
import {RadioField} from './RadioField';
import {EmailInputField} from './EmailInputField';
import {DateInputField} from './DateField';
import {TextBox} from './TextBox';
import {DescriptionBox} from './DescriptionBox';
import {DropdownField} from './DropdownField';
import {MultiSearchSelectionField} from './MultiSearchSelectionField';
import OrderSummary from "./OrderSummary";
import {getTranslation} from "../Utils/Utils";
import {useEffect, useState} from "react";

const StyledFormField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Label = styled(Heading3).attrs({
    as: "label",
    $noMargin: true,
})`
    font-size: var(--fs-label);
    display: inline-block;
    
    span { color: var(--color-input-required); }
`;

const Error = styled(Text).attrs({
    $noMargin: true,
})`
    color: var(--color-input-error);
`;

const formFields = {
    "textField": TextInputField,
    "textarea": TextAreaField,
    "email": EmailInputField,
    "date": DateInputField,
    "checkbox": CheckboxesField,
    "radio": RadioField,
    "text": TextBox,
    "dropdown": DropdownField,
    "multiSearch": MultiSearchSelectionField,
    "coGuide": EmailInputField
    // DescriptionBox is a standalone field
}

export const FormField = ({className, id, type, label, description, isRequired, isReadOnly, tooltip, price, options, error, ...props}) => {
    if (type === "descriptionBox") return <DescriptionBox label={label} description={description} />
    if (type === "orderSummary") return <OrderSummary />

    const FormFieldComponent = formFields[type];

    if (!FormFieldComponent) return null;

    return (
        <StyledFormField className={className}>
            {label && <Label htmlFor={id}>{label} {isRequired && <span>*</span>}</Label>}
            {description && <HTMLText dangerouslySetInnerHTML={{__html: description}} />}

            <FormFieldComponent
                id={id}
                label={label}
                required={isRequired}
                readOnly={isReadOnly}
                tooltip={tooltip}
                price={price}
                options={options}
                {...props}
            />

            {error && <FormFieldError error={error} />}
        </StyledFormField>
    );
}

const FormFieldError = ({error}) => {
    const [translation, setTranslation] = useState({
        "required": "",
        "unprocessable": ""
    });

    useEffect(() => {
        const fetchTranslation = async () => {
            let required = await getTranslation("RequiredQuestion")
            let unprocessable = await getTranslation("UnprocessableQuestion")

            setTranslation({
                "required": required.title,
                "unprocessable": unprocessable.title
            })
        };

        fetchTranslation();
    }, []);

    switch (error?.type) {
        case "required":
            return <Error>{translation.required}</Error>
        default:
            return <Error>{translation.unprocessable}</Error>
    }
}