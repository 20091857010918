import styled from 'styled-components';
import {Checkbox} from '../InputBase/Checkbox';
import {HTMLText, Text} from '../Text/Text';
import {Tooltip} from '../Tooltip/Tooltip';
import {getFormattedPrice} from '../../utils/helpers';

const StyledCheckboxesField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CheckboxWrapper = styled.label`
    display: flex;
    gap: 13px;
`;

const StyledCheckbox = styled(Checkbox)`
    &:checked + label {
        font-weight: var(--fw-bold);
    }
`;

const StyledCheckboxLabel = styled(Text).attrs({
    as: "label",
    $noMargin: true
})`
    display: inline-block;
    align-self: flex-start;
    margin-top: 1px;
    
    @media screen and (max-width: 768px) {
        margin-top: 3px;
    }
`;

// Prevent lonely wrap for tooltip
const TooltipWrapper = styled.span`
    display: inline;
    white-space: nowrap;
`;

const StyledTooltip = styled(Tooltip).attrs({
    variant: "small",
})`
    vertical-align: middle;
    margin-left: 4px;
`;

export const CheckboxesField = ({id, required, readOnly, options, ...props}) => {
    const defaultValueIds = props?.defaultValue || [];
    const defaultSelectedOptions = defaultValueIds?.length > 0 ? options?.filter(option =>
        defaultValueIds.includes(option.id)
    ) : null;

    // readOnly attr is not supported on checkbox, so use a hidden field with the defaultValue
    if (readOnly) {
        return (
            <>
                <StyledCheckboxesField>
                    {options?.map((option) => {
                        const defaultChecked = defaultSelectedOptions?.find(item => item.id === option.id);

                        return (
                            <CheckboxWrapper key={option.id}>
                                <StyledCheckbox
                                    id={option.id}
                                    value={option.id}
                                    defaultChecked={defaultChecked}
                                    disabled={readOnly}
                                />
                                <CheckboxLabel option={option} />
                            </CheckboxWrapper>
                        );
                    })}
                </StyledCheckboxesField>

                <input
                    type="hidden"
                    defaultValue={JSON.stringify(defaultSelectedOptions?.map(item => item.id))}
                    {...(props.register && props.register(props.name))}
                />
            </>
        )

    }

    return (
        <StyledCheckboxesField>
            {options?.map((option) => {
                const defaultChecked = defaultSelectedOptions?.find(item => item.id === option.id);

                return (
                    <CheckboxWrapper key={option.id}>
                        <StyledCheckbox
                            id={option.id}
                            value={option.id}
                            defaultChecked={defaultChecked}
                            {...(props.register && props.register(options?.length > 1 ? props.name : `${props.name}.0`, {
                                required,
                                onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() }
                            }))}
                            disabled={readOnly}
                        />
                        <CheckboxLabel option={option} />
                    </CheckboxWrapper>
                );
            })}
        </StyledCheckboxesField>
    );
}

const CheckboxLabel = ({option}) => {
    return (
        <StyledCheckboxLabel htmlFor={option.id}>
            {option.title}
            {!!option.price && ` (+€${getFormattedPrice(option.price)})`}
            {!!option.tooltip && <TooltipWrapper>&nbsp;<StyledTooltip><HTMLText dangerouslySetInnerHTML={{__html: option.tooltip}} /></StyledTooltip></TooltipWrapper>}
        </StyledCheckboxLabel>
    );
}