import * as React from "react"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import styled from 'styled-components';

const TooltipProvider = TooltipPrimitive.Provider
const TooltipBase = TooltipPrimitive.Root
const TooltipTrigger = TooltipPrimitive.Trigger
const TooltipArrow = TooltipPrimitive.Arrow
const TooltipPortal = TooltipPrimitive.Portal

const StyledTooltip = styled(TooltipPrimitive.Content)`
    border-radius: 8px;
    padding: 12px;
    background-color: var(--color-white);
    z-index: 99;
    max-width: 250px;
    font-size: var(--fs-info-tooltip);
    font-weight: var(--fw-regular);
    line-height: 1.15;
    box-shadow: var(--box-shadow);

    &.animate-in {
        animation: fadeInZoom 0.2s ease-in-out forwards;
    }

    &[data-state='closed'] { animation: fadeOutZoom 0.2s ease-in-out forwards; }
    &[data-side='bottom'] { animation: slideInFromTop 0.2s ease-in-out forwards; }
    &[data-side='left'] { animation: slideInFromRight 0.2s ease-in-out forwards; }
    &[data-side='right'] { animation: slideInFromLeft 0.2s ease-in-out forwards; }
    &[data-side='top'] { animation: slideInFromBottom 0.2s ease-in-out forwards; }

    @keyframes fadeInZoom {
        0% {
            opacity: 0;
            transform: scale(0.95);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes fadeOutZoom {
        0% {
            opacity: 1;
            transform: scale(1);
        }
        100% {
            opacity: 0;
            transform: scale(0.95);
        }
    }

    @keyframes slideInFromTop {
        0% {
            transform: translateY(-8px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes slideInFromRight {
        0% {
            transform: translateX(8px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slideInFromLeft {
        0% {
            transform: translateX(-8px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slideInFromBottom {
        0% {
            transform: translateY(8px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

const TooltipContent = React.forwardRef(({ className, sideOffset = 10, ...props }, ref) => (
    <StyledTooltip
        ref={ref}
        sideOffset={sideOffset}
        className={className}
        {...props}
    />
))

TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { TooltipBase, TooltipTrigger, TooltipContent, TooltipProvider, TooltipArrow, TooltipPortal }