import {Controller} from 'react-hook-form';
import {BaseSelect} from '../InputBase/BaseSelect';
import {getFormattedPrice} from '../../utils/helpers';

export const MultiSearchSelectionField = ({required, readOnly, options, control, ...props}) => {
    if (!control) return null;

    const selectOptions = options?.map(option => (
        {
            id: option.id,
            value: option.id,
            price: option.price,
            title: props.label + " - " + option.title,
            label: `${option.title}${!!option.price ? ` (+€${getFormattedPrice(option.price)})` : ''}`
        }
    ));

    const defaultValueIds = props?.defaultValue || [];
    const defaultSelectedOptions = defaultValueIds?.length > 0 ? selectOptions?.filter(option =>
        defaultValueIds.includes(option.value)
    ) : null;

    // readOnly attr is not supported on <select>, so use a hidden field with the defaultValue
    if (readOnly) {
        return (
            <>
                <BaseSelect
                    disabled={readOnly}
                    defaultValue={defaultSelectedOptions}
                    options={selectOptions}
                    placeholder="Select multiple"
                    isMulti={true}
                />

                <input
                    type="hidden"
                    defaultValue={JSON.stringify(defaultSelectedOptions?.map(item => item.value))}
                    {...(props.register && props.register(props.name))}
                />
            </>
        )
    }

    return (
        <Controller
            name={props.name}
            control={control}
            rules={{required}}
            render={({field}) => {
                return (
                    <BaseSelect
                        {...field}
                        disabled={readOnly}
                        defaultValue={defaultSelectedOptions}
                        options={selectOptions}
                        onBlur={() => { if(props.onFormFieldBlur) props.onFormFieldBlur() }}
                        placeholder="Select multiple"
                        isMulti={true}
                    />
                )
            }}
        />
    );
}