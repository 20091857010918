import styled from "styled-components";

const StyledFormFooter = styled.footer`
    display: flex;
    padding: 30px 0 50px 0;
    gap: 40px;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        row-gap: 20px;
        padding: 20px 16px 105px 16px;
    }
`;

const ReferenceLink = styled.a.attrs({
    target: "_blank",
    rel: "noreferrer"
})`
    font-size: var(--fs-footer);
    color: var(--color-footer-text);
    
    @media screen and (max-width: 768px) {
        margin: 0 auto;
        text-align: center;
    }
`;

const Copyright = styled.div`
    font-size: var(--fs-footer);
    color: var(--color-footer-text);
    margin-left: auto;

    @media screen and (max-width: 768px) {
        margin: 0 auto;
        text-align: center;
    }
`;

export const FormFooter = () => {
    // TODO: set URLs
    const config = {
        "introweken-owl-cms": {
            "title": "OWL",
            "website": {
                "title": "OWL Website",
                "url": "https://orientationweek.org/",
                "privacy": "https://orientationweek.org/terms-and-conditions/"
            }
        },
        "introweken-hop-cms": {
            "title": "HOP",
            "website": {
                "title": "HOP Website",
                "url": "https://www.hopweek.org/",
                "privacy": "https://www.hopweek.org/hopweek/privacy-statement-terms-conditions/"
            }
        },
        "introweken-elcid-cms": {
            "title": "EL-CID",
            "website": {
                "title": "EL-CID Website",
                "url": "https://elcidweek.nl/",
                "privacy": "https://elcidweek.nl/privacy-statement/"
            }
        }
    }
    const footer = config[process.env.REACT_APP_TARGET_CMS] || null;

    return (
        <StyledFormFooter>
            {footer && <ReferenceLink href={footer.website.url}>{footer.website.title}</ReferenceLink>}
            {footer && <ReferenceLink href={footer.website.privacy}>Terms of Privacy</ReferenceLink>}
            {footer && <Copyright>&copy; {footer.title} {new Date().getFullYear()}</Copyright>}
        </StyledFormFooter>
    );
}

export default FormFooter;