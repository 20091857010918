import {Modal} from './Modal';
import styled from 'styled-components';
import {Heading2} from '../Heading/Heading';
import {Button} from '../Button/Button';
import {Text} from '../Text/Text';

const StyledModal = styled(Modal)`
    max-width: 425px;
    width: 100%;
`;

const Content = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    max-height: 100%;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    padding: 30px;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }
`;

const StyledHeading2 = styled(Heading2).attrs({
    $align: "center",
    $noMargin: true
})`
    margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 40px;

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

ErrorModal.Header = function ErrorModal({children}) {
    return (
        <StyledHeading2>{children}</StyledHeading2>
    );
}

ErrorModal.Content = function ErrorModal({children}) {
    return (
        <Text $noMargin $align="center">
            {children}
        </Text>
    );
}

ErrorModal.Footer = function ErrorModal({children, ...props}) {
    return (
        <StyledButton type="button" {...props}>{children}</StyledButton>
    );
}

export default function ErrorModal({children, ...props}) {
    return (
        <StyledModal {...props}>
            <Content>
                {children}
            </Content>
        </StyledModal>
    );
}