import styled from 'styled-components';
import {Tooltip} from './Tooltip';
import {HTMLText} from '../Text/Text';

const StyledTooltip = styled(Tooltip)`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
`;

export const InputFieldTooltip = ({tooltip}) => {
    if (!tooltip) return null;

    return (
        <StyledTooltip>
            <HTMLText dangerouslySetInnerHTML={{__html: tooltip}} />
        </StyledTooltip>
    );
}