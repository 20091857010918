import {createGlobalStyle} from "styled-components";
import normalize from "styled-normalize";

const config = {
    "introweken-owl-cms": {
        "primary": "#050634",
        "secondary": "#40D0D4",
        "primaryHover": "#48E8ED",
    },
    "introweken-hop-cms": {
        "primary": "#2DBBF9",
        "secondary": "#157B9E",
        "primaryHover": "#4395B1",
    },
    "introweken-elcid-cms": {
        "primary": "#85BEAD",
        "secondary": "#145F64",
        "primaryHover": "#437F83",
    }
}
const theme = config[process.env.REACT_APP_TARGET_CMS] || null;

const GlobalStyle = createGlobalStyle`
    ${normalize}
    * {
        box-sizing: border-box;
    }

    :root {
        // Primitives
        --color-white: #fff; 
        --color-black: #000;
        
        --color-dark-grey: #171717;
        --color-dark-grey-rgb: 23, 23, 23;

        --color-primary: ${theme.primary};
        --color-secondary: ${theme.secondary};
        
        // Tokens
        --color-background: #F8F8F8;
        
        --color-heading: var(--color-dark-grey);
        --color-text: var(--color-dark-grey);
        --color-label: var(--color-dark-grey);
        --color-link: var(--color-secondary);
        --color-footer-text: rgba(var(--color-dark-grey-rgb), .4);

        --color-progress-step-text: rgba(var(--color-dark-grey-rgb), .4);
        --color-progress-step-divider: rgba(var(--color-dark-grey-rgb), .4);
        --color-progress-step-active: var(--color-secondary);
        
        --color-input-error: #B53434;
        --color-input-required: #B53434;
        --color-input-read-only-background: #F6F6F6;
        --color-input-read-only-text: rgba(var(--color-dark-grey-rgb), .4);
        --color-input-stroke: #E5E5E5;
        --color-input-description-box: #F6F6F6;
        --color-input-dropdown-option-selected: var(--color-secondary);
        --color-input-dropdown-option-hover: rgba(229, 229, 229, .35);
        --color-input-dropdown-option-multi-value: rgba(229, 229, 229, .3);

        --color-button-primary-hover: ${theme.primaryHover};
        --color-button-secondary-hover: rgba(var(--color-dark-grey-rgb), .6);
        --color-button-secondary-text: rgba(var(--color-dark-grey-rgb), .4);
        
        --color-status-success: var(--color-secondary);
        --color-status-failed: #B53434;
        --color-status-action: #D8833E;

        // Font properties
        --font: 'Roboto', sans-serif;
        --fw-thin: 100;
        --fw-extra-light: 200;
        --fw-light: 300;
        --fw-regular: 400;
        --fw-medium: 500;
        --fw-semi-bold: 600;
        --fw-bold: 700;
        --fw-extra-bold: 800;
        --fw-black: 900;

        // Font sizes
        --fs-heading-1: 3.6rem;
        --fs-heading-2: 2.4rem;
        --fs-heading-3: 2rem;
        --fs-text: 1.8rem;
        --fs-label: 2rem;
        --fs-tooltip: 1.6rem;
        --fs-button: 2rem;
        --fs-multi-value: 1.6rem;
        --fs-footer: 1.4rem;

        @media screen and (max-width: 768px) {
            --fs-heading-1: 2.1rem;
            --fs-heading-2: 2rem;
            --fs-heading-3: 1.8rem;
            --fs-text: 1.6rem;
            --fs-label: 1.8rem;
            --fs-tooltip: 1.4rem;
            --fs-button: 1.8rem;
        }
        
        // Box shadow
        --box-shadow: 0 8px 16px -2px rgba(27, 33, 44, .12);
        --box-shadow-up: 0 -4px 30px 0 rgba(0, 0, 0, .06);
    }

    html, body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
        font-size: 62.5%;
        font-family: var(--font), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        background-color: var(--color-background);
        line-height: 1.25;
        -webkit-text-size-adjust: 100%;
        tab-size: 4;
        font-feature-settings: normal;
    }

    // NProgress navigation loader bar
    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: var(--color-primary);
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }
`;

export default GlobalStyle;