import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';

const StyledNoMatch = styled.div``;

const NoMatch = () => {
    return (
        <StyledNoMatch>
            <Heading1>404 Page not found</Heading1>
            <Text>
                <Link to="/" reloadDocument>Back to home</Link>
            </Text>
        </StyledNoMatch>
    );
}

export default NoMatch;