import {Input} from '../InputBase/Input';
import styled from 'styled-components';

const StyledTextAreaField = styled(Input).attrs({
    as: "textarea"
})`
    resize: vertical;
`;

export const TextAreaField = ({id, label, required, readOnly, ...props}) => {
    return (
        <StyledTextAreaField
            id={id}
            placeholder={label}
            defaultValue={props?.defaultValue}
            {...(props.register && props.register(props.name, {
                required,
                onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() }
            }))}
            readOnly={readOnly}
            rows="8"
        />
    );
}