import styled from 'styled-components';
import checkboxInactive from '../../assets/icons/checkbox.svg';
import checkboxInactiveDisabled from '../../assets/icons/checkbox-disabled.svg';
import checkboxChecked from '../../assets/icons/checkbox-checked.svg';
import checkboxCheckedDisabled from '../../assets/icons/checkbox-checked-disabled.svg';

export const Checkbox = styled.input.attrs({
    type: "checkbox"
})`
    appearance: none;
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    content: url(${checkboxInactive});

    &:not(:disabled):checked {
        content: none;
        background-color: var(--color-secondary);
        mask: url(${checkboxChecked}) no-repeat center;
    }
    
    &:disabled {
        content: url(${checkboxInactiveDisabled});
    }

    &:disabled:checked {
        content: url(${checkboxCheckedDisabled});
    }
`;