import {redirect, useBeforeUnload, useFetcher, useNavigate, useOutletContext, useParams, useSearchParams} from 'react-router-dom';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {FormField} from '../../components/FormFields/FormField';
import {useFormContext} from '../../contexts/FormContext';
import styled from 'styled-components';
import FormNavigation from '../../components/Form/FormNavigation';
import {Statuses} from '../../constants/enums';
import {getData, postData} from '../../api/fetch';
import {useOpenModal} from '../../hooks/useOpenModal';
import ErrorModal from '../../components/Modal/ErrorModal';
import {AcceptedFormTargets} from '../../constants/formTargets';
import qs from 'qs';
import {removeEmptyStringValues} from '../../utils/helpers';

const StyledFormRoute = styled.div`
    padding: 0 40px;

    @media screen and (max-width: 768px) {
        padding: 0 16px;
    }
`;

const FormFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 75px 0;
    
    @media screen and (max-width: 768px) {
        padding: 30px 0 40px 0;
    }
`;

export async function formRouteAction({request, params}) {
    const {formTarget} = params;

    if (!formTarget || !(formTarget in AcceptedFormTargets)) {
        return {error: true, message: "There is something wrong with the form target. Please try again."}
    }

    let formData = await request.formData();
    const queryString = qs.stringify({
        studentId: formData.get("studentId") || undefined,
        mentorId: formData.get("mentorId") || undefined
    });
    formData.delete("studentId");
    formData.delete("mentorId");

    const res = await postData(`form/${AcceptedFormTargets[formTarget]}/submit${queryString ? `?${queryString}` : ''}`, formData, { handleErrorLocally: true })
    if (Boolean(res?.message) || Boolean(res?.error)) return {hasError: true, ...res};

    const formReplyId = res?.paymentId;

    if (Boolean(formReplyId)) {
        const paymentQueryString = qs.stringify({
            id: formReplyId
        })

        const paymentUrlRes = await getData(`form/${AcceptedFormTargets[formTarget]}/payment/url${paymentQueryString ? `?${paymentQueryString}` : ''}`, { handleErrorLocally: true });

        // TODO: Als paymentUrlRes fout gaat, dan niet de errors returnen (form is wel gesubmit), maar redirect naar error page?
        if (Boolean(paymentUrlRes?.message) || Boolean(paymentUrlRes?.error)) return {hasError: true, ...paymentUrlRes};

        const paymentUrl = paymentUrlRes?.paymentUrl;

        return redirect(paymentUrl);
    }

    return redirect(`/${formTarget}/success`)
}

export const availableSteps = [0];

const FormRoute = () => {
    const {data} = useOutletContext();
    const params = useParams();
    let [searchParams,] = useSearchParams();
    const navigate = useNavigate();
    const fetcher = useFetcher();
    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false)
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const {formValues, updateFormValues, stepIndex, setStepIndex} = useFormContext();
    const studentId = searchParams.get("studentId");
    const mentorId = searchParams.get("mentorId") || searchParams.get("guideId");
    const queryString = qs.stringify({
        studentId: studentId || undefined,
        mentorId: mentorId || undefined
    });
    const maxSteps = data?.steps?.length;
    const currentStepData = data?.steps?.[stepIndex];

    // When URL param changes, update the stepIndex
    useEffect(() => {
        // virtualStep === urlStepNumber when using Previous/Next step buttons in the form
        const urlStepNumber = params.stepNumber ? parseInt(params.stepNumber) : 1;
        const virtualStep = stepIndex + 1;

        // Prevent users from changing step in URL
        if (virtualStep === 1 && urlStepNumber !== 1) return navigate({
            pathname: `/${params.formTarget}/step/1`,
            ...(queryString && {search: queryString})
        });

        // When pressing previous browser button
        if (virtualStep > urlStepNumber) {
            const newStepIndex = stepIndex - 1;
            if (newStepIndex <= 0) return setStepIndex(0); // First step
            setStepIndex(newStepIndex);
        }

        // When pressing next browser button
        if (virtualStep < urlStepNumber) {
            const newStepIndex = stepIndex + 1;
            if (newStepIndex >= maxSteps) return; // Last step
            setStepIndex(newStepIndex);
        }

        //eslint-disable-next-line
    }, [params]);

    const {register, control, handleSubmit, formState: { errors, isDirty }, getValues } = useForm({
        defaultValues: {
            studentId: studentId || undefined,
            mentorId: mentorId || undefined,
            ...formValues?.[data?.id]
        }
    })

    // Before user unloads the page, give a warning if user touched the form
    useBeforeUnload(
        useCallback((e) => {
            if (isDirty && (status !== Statuses.SUBMITTING)) {
                e.preventDefault();
                e.returnValue = "";
            }
        }, [isDirty, status])
    );

    const onFormFieldBlur = () => {
        const values = getValues();
        updateFormValues(values, data.id);
    }

    const handlePreviousStep = () => {
        const newStepIndex = stepIndex - 1;

        if (newStepIndex <= 0) {
            setStepIndex(0)
            return navigate({
                pathname: `/${params.formTarget}/step/1`,
                ...(queryString && {search: queryString})
            });
        }

        setStepIndex(newStepIndex);
        return navigate({
            pathname: `/${params.formTarget}/step/${newStepIndex + 1}`,
            ...(queryString && {search: queryString})
        });
    }

    const handleNextStep = () => {
        const newStepIndex = stepIndex + 1;

        if (newStepIndex >= maxSteps) return;

        setStepIndex(newStepIndex);
        return navigate({
            pathname:`/${params.formTarget}/step/${newStepIndex + 1}`,
            ...(queryString && {search: queryString})
        });
    }

    function parseFormData(formData) {
        const result = {};

        for (const [key, value] of Object.entries(formData)) {
            if (Array.isArray(value)) {
                if (value.length === 0) {
                    result[key] = "";
                } else if (typeof value[0] === "string") {
                    result[key] = JSON.stringify(value);
                } else if (typeof value[0] === "object") {
                    const values = value.map(obj => obj.value || "").filter(v => v);
                    result[key] = JSON.stringify(values);
                } else {
                    result[key] = "";
                }
            } else if (typeof value === "object" && value !== null) {
                if (value.value) {
                    result[key] = value.value;
                } else {
                    result[key] = "";
                }
            } else if (typeof value === "string") {
                result[key] = value;
            } else {
                result[key] = "";
            }
        }

        return result;
    }

    const onSubmit = (data) => {

        // Should submit the form
        if (stepIndex + 1 >= maxSteps) {
            setError(false);
            setStatus(Statuses.SUBMITTING);
            const parsedData = parseFormData(data);
            return fetcher.submit(removeEmptyStringValues(parsedData), { method: "POST", action: `/${params.formTarget}/step/${maxSteps}`});
        }

        handleNextStep();
    }

    // Fetcher callback, handle error state
    useEffect(() => {
        if (fetcher?.state === "idle") {
            if (fetcher?.data) {
                setStatus(Statuses.IDLE);

                if (fetcher?.data?.hasError) {
                    setError(fetcher.data?.message || "Something went wrong, please try again.");
                    handleOpen();
                }
            }
        }
        //eslint-disable-next-line
    }, [fetcher]);

    return (
        <>
            <StyledFormRoute>
                <form id="form-route" onSubmit={handleSubmit(onSubmit)}>
                    <FormFields>
                        {currentStepData?.questions?.map(item => (
                            <FormField
                                key={item.id}
                                id={item.id}
                                type={item.type}
                                label={item.title}
                                description={item.description}
                                isRequired={item.required}
                                isReadOnly={item.readOnly}
                                tooltip={item.tooltip}
                                price={item.price}
                                options={item.options}
                                error={errors?.[item.id]}
                                name={item.id}
                                defaultValue={item.value}
                                register={register}
                                control={control}
                                onFormFieldBlur={onFormFieldBlur}
                            />
                        ))}
                    </FormFields>
                </form>
            </StyledFormRoute>

            <FormNavigation
                handlePreviousStep={handlePreviousStep}
                handleNextStep={handleNextStep}
                maxSteps={maxSteps}
                status={status}
            />

            <ErrorModal isOpen={isOpen} handleClose={handleClose}>
                <ErrorModal.Header>Form submission incomplete</ErrorModal.Header>
                <ErrorModal.Content>{error}</ErrorModal.Content>
                <ErrorModal.Footer onClick={handleClose}>Understood</ErrorModal.Footer>
            </ErrorModal>
        </>
    )
}

export default FormRoute;