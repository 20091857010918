import styled from 'styled-components';
import {HTMLText, Text} from '../Text/Text';
import {Tooltip} from '../Tooltip/Tooltip';
import {getFormattedPrice} from '../../utils/helpers';
import {Radio} from '../InputBase/Radio';

const StyledRadioField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const RadioWrapper = styled.label`
    display: flex;
    gap: 13px;
`;

const StyledRadio = styled(Radio)`
    &:checked + label {
        font-weight: var(--fw-bold);
    }
`;

const StyledRadioLabel = styled(Text).attrs({
    as: "label",
    $noMargin: true
})`
    display: inline-block;
    align-self: flex-start;
    margin-top: 1px;

    @media screen and (max-width: 768px) {
        margin-top: 3px;
    }
`;

// Prevent lonely wrap for tooltip
const TooltipWrapper = styled.span`
    display: inline;
    white-space: nowrap;
`;

const StyledTooltip = styled(Tooltip).attrs({
    variant: "small",
})`
    vertical-align: middle;
    margin-left: 4px;
`;

export const RadioField = ({id, required, readOnly, options, ...props}) => {
    const defaultSelectedOption = props?.defaultValue ? options?.find(item => item.id === props?.defaultValue) : null;

    // readOnly attr is not supported on radio, so use a hidden field with the defaultValue
    if (readOnly) {
        return (
            <>
                <StyledRadioField>
                    {options?.map((option) => (
                        <RadioWrapper key={option.id}>
                            <StyledRadio
                                id={option.id}
                                value={option.id}
                                defaultChecked={defaultSelectedOption?.id === option.id}
                                disabled={readOnly}
                            />
                            <RadioLabel option={option} />
                        </RadioWrapper>
                    ))}
                </StyledRadioField>

                <input
                    type="hidden"
                    defaultValue={defaultSelectedOption}
                    {...(props.register && props.register(props.name))}
                />
            </>
        )
    }

    return (
        <StyledRadioField>
            {options?.map((option) => (
                <RadioWrapper key={option.id}>
                    <StyledRadio
                        id={option.id}
                        value={option.id}
                        defaultChecked={defaultSelectedOption?.id === option.id}
                        {...(props.register && props.register(props.name, {
                            required,
                            onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() }
                        }))}
                        disabled={readOnly}
                    />
                    <RadioLabel option={option} />
                </RadioWrapper>
            ))}
        </StyledRadioField>
    );
}

const RadioLabel = ({option}) => {
    return (
        <StyledRadioLabel htmlFor={option.id}>
            {option.title}
            {!!option.price && ` (+€${getFormattedPrice(option.price)})`}
            {!!option.tooltip && <TooltipWrapper>&nbsp;<StyledTooltip><HTMLText dangerouslySetInnerHTML={{__html: option.tooltip}} /></StyledTooltip></TooltipWrapper>}
        </StyledRadioLabel>
    );
}