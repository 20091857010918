export const Statuses = {
    LOADING: "loading",
    REVALIDATING: "revalidating",
    IDLE: "idle",
    SUBMITTING: "submitting",
    SUCCESS: "success"
};

export const Environments = {
    DEV: "dev",
    TEST: "test",
    ACCEPT: "acceptance",
    PRODUCTION: "production",
    DEFAULT: "default",
}