import styled from 'styled-components';
import {Link, useRouteError} from 'react-router-dom';
import {Heading2} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import FormFooter from "../../components/Form/FormFooter";
import {Container, getTranslation} from "../../components/Utils/Utils";
import {ReactComponent as Cross} from '../../assets/icons/cross.svg';
import {ReactComponent as Success} from '../../assets/icons/success.svg';
import {ReactComponent as Exclamation} from '../../assets/icons/exclamation.svg';
import {Button} from "../../components/Button/Button";
import {AcceptedFormTargets} from "../../constants/formTargets";
import {getData} from "../../api/fetch";
import {useEffect, useState} from "react";

const StyledErrorBoundary = styled.div`
    padding: 40px 0;
    margin: auto 0;
    
    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const StyledContainer = styled(Container)`
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const Inner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    padding: 52px 40px;
    
    @media screen and (max-width: 768px) {
        padding: 40px 16px;
    }
`;

const Icon = styled.div`
    padding: 12px;
    border-radius: 50%;
    background-color: ${({$status}) => $status === "action" ? "var(--color-status-action)" : $status === "success" ? "var(--color-status-success)" : "var(--color-status-failed)"};
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
`;

const StyledCross = styled(Cross)`
    width: 56px;
    height: 56px;
`;

const StyledSuccess = styled(Success)`
    width: 56px;
    height: 56px;
`;

const StyledExclamation = styled(Exclamation)`
    width: 56px;
    height: 56px;
`;

const PaymentButton = styled(Button)`
    margin-bottom: 10px;
`;

export async function restartPayment({formReplyId}) {
    const formTarget = window.location.pathname.replace(/^\/|\/$/g, '');

    if (!formReplyId || !formTarget || !(formTarget in AcceptedFormTargets)) {
        return;
    }

    const paymentUrlRes = await getData(`form/${AcceptedFormTargets[formTarget]}/payment/url?id=${formReplyId}`, {handleErrorLocally: true});
    const paymentUrl = paymentUrlRes?.paymentUrl;
    window.location.replace(paymentUrl)
}

const ErrorBoundary = () => {
    const [translation, setTranslation] = useState({
        "title": "",
        "description": "",
        "footer": "",
        "button": ""
    });

    const error = useRouteError();

    const config = {
        "introweken-owl-cms": "info@owl.leidenuniv.nl",
        "introweken-hop-cms": "info@hop.leidenuniv.nl",
        "introweken-elcid-cms": "info@elcid.leidenuniv.nl"
    }
    const email = config[process.env.REACT_APP_TARGET_CMS] || null;

    let icon = <StyledCross />;
    let button;
    let status = "failure";

    useEffect(() => {
        const fetchTranslation = async () => {
            let general = await getTranslation(error.key)
            let footer = await getTranslation("Footer")
            let button = await getTranslation("StartPayment")

            setTranslation({
                "title": general.title,
                "description": general.description,
                "footer": footer.description,
                "button": button.title
            });
        };

        fetchTranslation();
    }, [error.key, error.status]);


    if (error) {
        switch (error.status) {
            case 400:
                break;
            case 401:
                break;
            case 402:
                icon = <StyledExclamation />;
                button = <PaymentButton onClick={() => restartPayment({formReplyId: error?.link})}>{translation.button}</PaymentButton>
                status = "action";
                break;
            case 404:
                break;
            case 409:
                icon = <StyledSuccess />;
                status = "success"
                break;
            default:
                setTranslation({
                    "title": "Something went wrong",
                    "description": "Please try again later...",
                    "footer": "Have any questions? Please contact"
                });
                break;
        }
    }

    return (
        <StyledErrorBoundary>
            <StyledContainer>
                <Inner>
                    <Icon $status={status}>
                        {icon}
                    </Icon>
                    <Heading2 $align="center">{translation.title}</Heading2>
                    <Text $align="center">{translation.description}</Text>
                    {button}
                    {email && <Text $align="center">{translation.footer} <Link to={`mailto:${email}`}>{email}</Link></Text>}
                </Inner>
            </StyledContainer>

            <Container>
                <FormFooter />
            </Container>
        </StyledErrorBoundary>
    );
}

export default ErrorBoundary;