import Select, {components} from 'react-select';
import selectChevron from '../../assets/icons/chevron-down.svg';
import selectChevronDisabled from '../../assets/icons/chevron-down-disabled.svg';
import {ReactComponent as Close} from '../../assets/icons/close.svg';
import {forwardRef} from 'react';
import styled, {css} from 'styled-components';

const StyledClearIndicator = styled(Close)`
    width: 22px;
    height: 22px;

    ${({$isDisabled}) => $isDisabled ? css`
        path {
            fill: var(--color-dark-grey);
            fill-opacity: .4; 
        }
    ` : css`
        path {
            fill: var(--color-secondary);
        }
    `};
    
    @media screen and (max-width: 768px) {
        width: 20px;
        height: 20px;
    }
`;

const StyledMultiValueRemove = styled(Close)`
    width: 16px;
    height: 16px;
    
    ${({$isDisabled}) => $isDisabled && css`path { fill-opacity: .4; }`}
`;

// Overwrite react-select components
const Control = ({children, ...props}) => (
    <components.Control {...props}>
        {children}
    </components.Control>
);

const Input = ({...props}) => (
    <components.Input {...props} aria-activedescendant={undefined} />
);

const IndicatorsContainer = ({...props}) => (
    <components.IndicatorsContainer {...props} />
);

const ClearIndicator = ({...props}) => {
    const {selectProps: { isDisabled }} = props;

    return (
        <components.ClearIndicator {...props}>
            <StyledClearIndicator $isDisabled={isDisabled} />
        </components.ClearIndicator>
    );
}

const DropdownIndicator = () => null;
const IndicatorSeparator = () => null;

const MultiValueRemove = ({...props}) => {
    const {selectProps: { isDisabled }} = props;

    return (
        <components.MultiValueRemove {...props}>
            <StyledMultiValueRemove $isDisabled={isDisabled} />
        </components.MultiValueRemove>
    )
};

export const BaseSelect = forwardRef(function BaseSelect(props, ref) {
    const {options, placeholder, onChange, defaultValue, isMulti = false, disabled, ...otherProps} = props;

    return (
        <>
            <Select
                ref={ref}
                options={options}
                placeholder={placeholder}
                isMulti={isMulti}
                isDisabled={disabled}
                isSearchable={true}
                isClearable={!isMulti}
                closeMenuOnSelect={!isMulti}
                noOptionsMessage={() => "No options found"}
                onChange={onChange}
                defaultValue={defaultValue}
                unstyled={true}
                components={{
                    Control,
                    Input,
                    DropdownIndicator,
                    IndicatorsContainer,
                    IndicatorSeparator,
                    MultiValueRemove,
                    ClearIndicator
                }}
                styles={{
                    container: (baseStyles) => ({
                        ...baseStyles,
                        width: "100%",
                    }),
                    control: (baseStyles, {isDisabled}) => ({
                        ...baseStyles,
                        width: "100%",
                        minHeight: "50px",
                        padding: isMulti ? "8px 50px 8px 16px" : "13px 50px 13px 16px",
                        borderRadius: "10px",
                        color: isDisabled ? "var(--color-input-read-only-text)" : "var(--color-text)",
                        fontSize: "var(--fs-text)",
                        backgroundColor: isDisabled ? "var(--color-input-read-only-background)" : "var(--color-white)",
                        border: "1px solid var(--color-input-stroke)",
                        textAlign: "left",
                        cursor: "pointer",
                        ...(isMulti && {
                            display: "flex",
                            alignItems: "center",
                        }),
                        "&:after": {
                            content: '""',
                            position: "absolute",
                            top: "50%",
                            right: "13px",
                            transform: "translateY(-50%)",
                            width: "24px",
                            height: "24px",
                            backgroundColor: "var(--color-secondary)",
                            mask: isDisabled ? `url(${selectChevronDisabled}) no-repeat center` : `url(${selectChevron}) no-repeat center`
                        }
                    }),
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: "var(--color-text)",
                        opacity: ".3",
                        fontStyle: "italic",
                    }),
                    input: (baseStyles) => ({
                        ...baseStyles
                    }),
                    valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        gap: "10px",
                    }),
                    menu: (baseStyles) => ({
                        ...baseStyles,
                        background: "var(--color-white)",
                        marginTop: "8px",
                        borderRadius: "10px",
                        overflow: "hidden",
                        border: "1px solid var(--color-input-stroke)",
                        zIndex: "100",
                    }),
                    option: (baseStyles, {isSelected}) => ({
                        ...baseStyles,
                        cursor: "pointer",
                        padding: "16px",
                        background: isSelected && "var(--color-input-dropdown-option-selected)",
                        fontSize: "var(--fs-text)",
                        color: isSelected ? "var(--color-white)" : "var(--color-text)",
                        '&:hover': {
                            background: !isSelected && "var(--color-input-dropdown-option-hover)",
                        }
                    }),
                    noOptionsMessage: () => ({
                        color: "var(--color-text)",
                        textAlign: "center",
                        fontSize: "var(--fs-text)",
                        padding: "20px",
                    }),
                    singleValue: (baseStyles) => ({
                        ...baseStyles,
                    }),
                    multiValue: (baseStyles) => ({
                        ...baseStyles,
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 7px",
                        borderRadius: "3px",
                        gap: "5px",
                        fontSize: "var(--fs-multi-value)",
                        backgroundColor: "var(--color-input-dropdown-option-multi-value)",
                        border: "1px solid var(--color-input-stroke)",
                    }),
                    multiValueLabel: (baseStyles) => ({
                        ...baseStyles,
                        color: "var(--color-blue-50)",
                        fontSize: "var(--fs-text-small)",
                    }),
                    multiValueRemove: (baseStyles) => ({
                        ...baseStyles,
                        color: "var(--color-blue-50)",
                    }),
                    clearIndicator: (baseStyles) => ({
                        ...baseStyles
                    })
                }}
                {...otherProps}
            />
        </>
    );
})