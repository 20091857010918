import {Input} from '../InputBase/Input';
import styled from 'styled-components';
import {InputFieldTooltip} from '../Tooltip/InputTooltip';

const StyledEmailInputField = styled.div`
    position: relative;
`;

export const EmailInputField = ({id, label, required, readOnly, tooltip, ...props}) => {
    return (
        <StyledEmailInputField>
            <Input
                id={id}
                type="email"
                placeholder={label}
                defaultValue={props?.defaultValue}
                {...(props.register && props.register(props.name, {
                    required,
                    onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() }
                }))}
                readOnly={readOnly}
                $hasTooltip={!!tooltip}
            />
            <InputFieldTooltip tooltip={tooltip} />
        </StyledEmailInputField>
    );
}