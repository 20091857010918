import {Controller} from 'react-hook-form';
import {BaseSelect} from '../InputBase/BaseSelect';
import {getFormattedPrice} from '../../utils/helpers';

export const DropdownField = ({required, readOnly, options, control, ...props}) => {
    if (!control) return null;

    const selectOptions = options?.map(option => (
        {
            id: option.id,
            value: option.id,
            price: option.price,
            title: props.label + " - " + option.title,
            label: `${option.title}${!!option.price ? ` (+€${getFormattedPrice(option.price)})` : ''}`
        }
    ));

    const defaultSelectedOption = props?.defaultValue ? selectOptions?.find(item => item.id === props?.defaultValue) : null;

    // readOnly attr is not supported on <select>, so use a hidden field with the defaultValue
    if (readOnly) {
        return (
            <>
                <BaseSelect
                    disabled={readOnly}
                    defaultValue={defaultSelectedOption}
                    options={selectOptions}
                    placeholder="Select"
                />

                <input
                    type="hidden"
                    defaultValue={defaultSelectedOption?.id}
                    {...(props.register && props.register(props.name))}
                />
            </>
        )
    }

    return (
        <Controller
            name={props.name}
            control={control}
            rules={{required}}
            render={({field}) => {
                return (
                    <BaseSelect
                        {...field}
                        disabled={readOnly}
                        defaultValue={defaultSelectedOption}
                        options={selectOptions}
                        onBlur={() => { if(props.onFormFieldBlur) props.onFormFieldBlur() }}
                        placeholder="Select"
                    />
                )
            }}
        />
    );
}